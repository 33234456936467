@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Otomanopee+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #212121;
  font-family: -apple-system, BlinkMacSystemFont, "Oswald", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #212121;
}

ul {
  list-style-type: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color-1: #d61a5e;
  --primary-color-2: #ff8c05;
}

/* Common Styles */

.wrapper {
  position: relative;
}

.container {
  max-width: 81.25rem;
  margin: 0 auto;
}

.section-title {
  color: #fff;
  font-weight: 200;
  font-size: 4rem;
}

.grid {
  display: grid;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.text-white {
  color: #fff;
}

.text-gradient {
  background: -webkit-linear-gradient(
    right,
    var(--primary-color-1),
    var(--primary-color-2)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fw-200 {
  font-weight: 200;
}

.circle-shape {
  height: 1rem;
  width: 1rem;
  background: #fff;
  border-radius: 50%;
}

.circle-shape::after {
  content: "";
  display: block;
  height: 350px;
  width: 2px;
  background: #fff;
  margin: 0 auto;
}

/* Navbar Styles */

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 4rem;
  height: 5rem;
  background-color: #262626;
  width: 100%;
}

.navbar h1 {
  background: -webkit-linear-gradient(
    right,
    var(--primary-color-1),
    var(--primary-color-2)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  font-size: 3rem;
}

.navbar .nav-links a {
  color: #fff;
  margin: 0.5rem 0.75rem;
  transition: background 0.2s ease-in-out;
  padding: 0.625rem;
}

.navbar .nav-links a:hover {
  background: -webkit-linear-gradient(
    right,
    var(--primary-color-1),
    var(--primary-color-2)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 0.125rem #ff8c05 solid;
  border-bottom: 0.125rem var(--primary-color-2) solid;
}

.nav-hamburger {
  cursor: pointer;
  display: none;
}

/* EOF Navbar Styles */

/* Header Styles */

.header {
  margin: 2.5rem 1rem;
  border-radius: 0.625rem;
  background-color: #262626;
  padding: 2.5rem;
  box-shadow: 0px 2px 9px 3px rgba(145, 144, 144, 0.75);
  -webkit-box-shadow: 0px 2px 9px 3px rgba(145, 144, 144, 0.75);
  -moz-box-shadow: 0px 2px 9px 3px rgba(145, 144, 144, 0.75);
  min-height: 21.875rem;
}

.header-grid {
  grid-template-columns: 1fr 3fr;
}

.header .img-container img {
  width: 100%;
  border-radius: 50%;
  justify-self: center;
}

.header .text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.header .text-container h2 {
  color: #fff;
  font-size: 2.5rem;
  letter-spacing: 1px;
  font-weight: 300;
  text-align: center;
  font-family: "Otomanopee One", sans-serif;
  margin-bottom: 1.5rem;
}

.header .text-container h1 {
  color: #fff;
  font-size: 4rem;
  text-transform: uppercase;
  background: -webkit-linear-gradient(
    right,
    var(--primary-color-1),
    var(--primary-color-2)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  text-align: center;
  margin-bottom: 1.5rem;
}

.contact-me-btn {
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  border: 2px #fff solid;
  background: #ff8c05;
  background: var(--primary-color-2);
  color: #fff;
  cursor: pointer;
  margin-top: 1rem;
  transition: background 0.2s ease-in;
  border-radius: 30px;
}

.contact-me-btn:hover {
  background: #d61a5e;
  background: var(--primary-color-1);
}

@media only screen and (max-width: 1250px) {
  .header .text-container h1 {
    font-size: 3.2rem;
  }
}

@media only screen and (max-width: 1050px) {
  .header .text-container h1 {
    font-size: 2.5rem;
  }
  .header .text-container h2 {
    font-size: 2rem;
  }

  .contact-me-btn {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0.6rem;
  }
}

@media only screen and (max-width: 850px) {
  .header-grid {
    grid-template-columns: 1fr;
  }
  .header .img-container img {
    display: none;
  }

  .nav-links {
    display: none;
  }

  .nav-hamburger {
    display: block;
  }

  .overlaynav-wrapper {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@media only screen and (max-width: 650px) {
  .header .text-container h1 {
    font-size: 2rem;
  }
  .header .text-container h2 {
    font-size: 1.2rem;
  }

  .navbar {
    padding: 0.7rem 2rem;
  }
}

/* EOF Header Styles */

/* Experience Component Styles */

.experience-grid {
  grid-template-columns: 1fr 2.5fr;
}

.experience-container {
  margin: 2.5rem 1rem;
  border-bottom: 3px solid #fff;
  padding-bottom: 2rem;
}

.experience-grid .card {
  box-shadow: 0px 2px 9px 3px rgba(145, 144, 144, 0.75);
  -webkit-box-shadow: 0px 2px 9px 3px rgba(145, 144, 144, 0.75);
  -moz-box-shadow: 0px 2px 9px 3px rgba(145, 144, 144, 0.75);
  border-radius: 8px;
}

.experience-card {
  padding: 1rem 2rem;
  grid-template-columns: 1fr 10fr;
  margin-bottom: 2rem;
}

.experience-card-container > *:last-child {
  overflow: hidden;
}

.card .company-title {
  font-size: 2rem;
  font-weight: 400;
  color: #ff8c05;
  color: var(--primary-color-2);
}

.position-time {
  color: #fff;
  font-weight: 300;
  font-size: 1.5rem;
  margin-bottom: 0.6rem;
}

.position-description {
  list-style-type: disc;
  list-style-position: inside;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 1rem;
  color: #aeaeae;
}

@media only screen and (max-width: 1250px) {
  /*  */
}

@media only screen and (max-width: 1050px) {
  .section-title {
    font-size: 3rem;
  }

  .experience-grid {
    grid-template-columns: 1fr 3fr;
  }

  .card .company-title {
    font-size: 1.6rem;
  }

  .position-time {
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
  }
}

@media only screen and (max-width: 850px) {
  .experience-grid {
    grid-template-columns: 1fr;
  }

  .section-title {
    text-align: center;
  }
}

@media only screen and (max-width: 650px) {
  .experience-card {
    padding: 1rem 0.5rem;
    grid-template-columns: 1fr 15fr;
    margin-bottom: 2rem;
  }

  .card .company-title {
    font-size: 1.2rem;
  }

  .position-time {
    font-size: 0.8rem;
    margin-bottom: 0.4rem;
  }

  .position-description {
    font-size: 0.8rem;
  }
}

/* EOF Experience Component Styles */

/* Skills Component Styles */

.skills-container {
  margin: 2.5rem 1rem;
  border-bottom: 3px solid #fff;
  padding-bottom: 2rem;
}

.skills-grid {
  grid-template-columns: 1fr 2.5fr;
}

.skill-category-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.skill-category-title {
  color: #ff8c05;
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.skill {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(25% - 1rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

.skill-name {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 300;
}

@media only screen and (max-width: 1250px) {
  /*  */
}

@media only screen and (max-width: 1050px) {
  .skills-grid {
    grid-template-columns: 1fr 3fr;
  }
}

@media only screen and (max-width: 850px) {
  .skills-grid {
    grid-template-columns: 1fr;
  }

  .section-title {
    text-align: center;
  }
}

@media only screen and (max-width: 650px) {
  .skill {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 1rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
  }
}

/* EOF Skills Component Styles */

/* Blogs Component Styles */

.blogs-container {
  margin: 2.5rem 1rem;
  border-bottom: 3px solid #fff;
  padding-bottom: 2rem;
}

.blogs-grid {
  grid-template-columns: 1fr 3fr;
}

.blogs-data-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.blog-card {
  background: #fff;
  position: relative;
  margin-bottom: 2rem;
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(40% - 2rem);
}

.blog-card:hover .blog-title {
  background: -webkit-linear-gradient(
    right,
    var(--primary-color-1),
    var(--primary-color-2)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.blog-img-container img {
  width: 100%;
}

.blog-card-body {
  padding: 1rem;
}

.blog-description {
  line-height: 1.8rem;
}

.blog-title {
  text-transform: capitalize;
  color: #262626;
}

.meta-info {
  text-transform: uppercase;
  font-size: 90%;
  font-weight: 700;
  color: #42465a;
}

.blog-author {
  color: #ff8c05;
  color: var(--primary-color-2);
}

.blog-meta-info .blog-author::after {
  content: "-";
  margin: 0 0.5rem;
  color: #bbb;
}

.blog-published-date {
  color: #ff8c05;
  color: var(--primary-color-2);
}

.blog-tag {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: #ff8c05;
  background: var(--primary-color-2);
  color: #fff;
  padding: 0.2rem 1rem;
  text-transform: uppercase;
  font-size: 80%;
  border-radius: 45px;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
  font-weight: 600;
}

@media only screen and (max-width: 1250px) {
  .blog-card {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 2rem);
  }
}

@media only screen and (max-width: 1050px) {
  .blogs-grid {
    grid-template-columns: 1fr 3fr;
  }
}

@media only screen and (max-width: 850px) {
  .blogs-grid {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 650px) {
  .blog-card {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(100%);
  }
}

/* EOF Blogs Component Styles */

/* Project Component Styles */

.projects-container {
  margin: 2.5rem 1rem;
  border-bottom: 3px solid #fff;
  padding-bottom: 2rem;
}

.projects-grid {
  grid-template-columns: 1fr 3fr;
}

.projects-data-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.project-card {
  background: #fff;
  position: relative;
  margin-bottom: 2rem;
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(40% - 2rem);
}

.project-img-container img {
  width: 100%;
}

.project-card-body {
  padding: 1rem;
}

.project-description {
  line-height: 1.8rem;
}

.project-title {
  text-transform: capitalize;
  color: #262626;
}

.project-tag {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: #ff8c05;
  background: var(--primary-color-2);
  color: #fff;
  padding: 0.2rem 1rem;
  text-transform: uppercase;
  font-size: 80%;
  border-radius: 45px;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
  font-weight: 600;
}

.project-links {
  margin-top: 1rem;
}

.live-link {
  color: #fff;
  background-color: #d61a5e;
  background-color: var(--primary-color-1);
  padding: 0.2rem 0.6rem;
  border-radius: 20px;
  margin-right: 1rem;
  transition: opacity 0.2s ease-in-out;
}

.live-link:hover {
  opacity: 0.7;
}

.github-link {
  color: #fff;
  background-color: #0d1117;
  padding: 0.2rem 0.6rem;
  border-radius: 20px;
  transition: opacity 0.2s ease-in-out;
}

.github-link:hover {
  opacity: 0.7;
}

.github-btn {
  padding: 0.5rem 1rem;
  width: -webkit-max-content;
  width: max-content;
  border: 3px solid #fff;
  background: #0d1117;
  color: #fff;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 100;
  box-shadow: 0px 2px 9px 3px rgba(145, 144, 144, 0.75);
  -webkit-box-shadow: 0px 2px 9px 3px rgba(145, 144, 144, 0.75);
  -moz-box-shadow: 0px 2px 9px 3px rgba(145, 144, 144, 0.75);
  cursor: pointer;
  transition: background color border 0.2s ease-in-out;
}

.github-btn:hover {
  background: #fff;
  color: #0d1117;
  border: 3px solid #0d1117;
}

.projects-data-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1250px) {
  .project-card {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 2rem);
  }
}

@media only screen and (max-width: 1050px) {
  .projects-grid {
    grid-template-columns: 1fr 3fr;
  }
}

@media only screen and (max-width: 850px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 650px) {
  .project-card {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(100%);
  }
}

/* EOF Project Component Styles */

/* Footer Styles */

.footer {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-title {
  font-weight: 400;
  background: -webkit-linear-gradient(
    right,
    var(--primary-color-1),
    var(--primary-color-2)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.social-link {
  padding: 1rem;
}

.social-links a {
  color: #fff;
}

.social-link a:hover {
  background: -webkit-linear-gradient(
    right,
    var(--primary-color-1),
    var(--primary-color-2)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 850px) {
  .social-link {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(33.33% -1rem);
  }
}

/* EOF Footer Styles */

/* Contact Component */

.contact-grid {
  grid-template-columns: 1fr 2.5fr;
}

.contact-container {
  margin: 2.5rem 1rem;
  border-bottom: 3px solid #fff;
  padding-bottom: 2rem;
}

.contact-data-container {
  color: #fff;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.contact-wrapper {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(30% - 2rem);
  margin-bottom: 2rem;
}

@media only screen and (max-width: 1250px) {
  /*  */
}

@media only screen and (max-width: 1050px) {
  .contact-grid {
    grid-template-columns: 1fr 3fr;
  }
}

@media only screen and (max-width: 850px) {
  .contact-grid {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 650px) {
  .contact-wrapper {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(100% - 2rem);
  }
}

/* EOF Contact Component */

